import { render, staticRenderFns } from "./GroupChatDropDownMenu.vue?vue&type=template&id=04647403&scoped=true&"
import script from "./GroupChatDropDownMenu.vue?vue&type=script&lang=js&"
export * from "./GroupChatDropDownMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04647403",
  null
  
)

export default component.exports